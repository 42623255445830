<template>
  <footer class="the-footer flex-wrap justify-between" :class="classes">
    <span>
      COPYRIGHT ©
      <a href="https://www.clickdefense.io" target="_blank"> ClickDefense</a> {{ new Date().getFullYear() }}. All rights reserved.
    </span>
  </footer>
</template>

<script>

export default {
  name: "the-footer",
  props: {
    classes: {
      type: String,
    },
  }
}
</script>
