var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "parentx" },
    [
      _c(
        "vs-sidebar",
        {
          ref: "mainSidebar",
          staticClass: "sidebarx main-menu-sidebar items-no-padding",
          attrs: {
            parent: _vm.parent,
            hiddenBackground: true,
            reduce: _vm.reduce,
            "default-index": "-1",
            "click-not-close": _vm.clickNotClose,
            "reduce-not-rebound": _vm.reduceNotRebound
          },
          model: {
            value: _vm.isSidebarActive,
            callback: function($$v) {
              _vm.isSidebarActive = $$v
            },
            expression: "isSidebarActive"
          }
        },
        [
          _c(
            "div",
            {
              on: {
                mouseenter: _vm.sidebarMouseEntered,
                mouseleave: _vm.sidebarMouseLeave
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "header-sidebar flex items-end justify-between",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [
                  _c("div", { staticClass: "logo flex items-center" }, [
                    _vm.logo_left
                      ? _c("img", {
                          staticClass: "logo-left w-10",
                          attrs: { src: _vm.logo_left, alt: "logo" }
                        })
                      : _vm._e(),
                    _vm.logo_right
                      ? _c("img", {
                          staticClass: "logo-right w-10",
                          attrs: { src: _vm.logo_right, alt: "logo" }
                        })
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    [
                      _vm.showCloseButton
                        ? [
                            _c("feather-icon", {
                              staticClass: "m-0 cursor-pointer",
                              attrs: { icon: "XIcon" },
                              on: {
                                click: function($event) {
                                  return _vm.$store.commit(
                                    "TOGGLE_IS_SIDEBAR_ACTIVE",
                                    false
                                  )
                                }
                              }
                            })
                          ]
                        : !_vm.showCloseButton && !_vm.sidebarItemsMin
                        ? [
                            _c("feather-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.reduce,
                                  expression: "!reduce"
                                }
                              ],
                              staticClass: "btn-toggle mr-0 cursor-pointer",
                              attrs: {
                                icon: "LockIcon",
                                "svg-classes": "stroke-current",
                                id: "btnSidebarToggler"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleReduce(true)
                                }
                              }
                            }),
                            _c("feather-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.reduce,
                                  expression: "reduce"
                                }
                              ],
                              staticClass: "btn-toggle mr-0 cursor-pointer",
                              attrs: {
                                icon: "UnlockIcon",
                                "svg-classes": "stroke-current",
                                id: "btnSidebarToggler"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleReduce(false)
                                }
                              }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ]
              ),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showShadowBottom,
                    expression: "showShadowBottom"
                  }
                ],
                staticClass: "shadow-bottom"
              }),
              _c(
                "VuePerfectScrollbar",
                {
                  ref: "mainSidebarPs",
                  staticClass: "scroll-area--main-sidebar pt-2",
                  attrs: { settings: _vm.settings },
                  on: { "ps-scroll-y": _vm.psSectionScroll }
                },
                [
                  _vm._l(_vm.sidebarItems, function(sidebarItem, index) {
                    return [
                      sidebarItem.header && !_vm.sidebarItemsMin
                        ? _c(
                            "span",
                            {
                              key: "header-" + index,
                              staticClass: "navigation-header truncate"
                            },
                            [_vm._v(_vm._s(sidebarItem.header))]
                          )
                        : !sidebarItem.header
                        ? [
                            !sidebarItem.submenu
                              ? _c(
                                  "vx-sidebar-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          sidebarItem.user.includes(
                                            _vm.$acl.get[0]
                                          ) || sidebarItem.user == "user",
                                        expression:
                                          "sidebarItem.user.includes($acl.get[0]) || sidebarItem.user== 'user'"
                                      }
                                    ],
                                    key: "sidebarItem-" + index,
                                    ref: "sidebarLink",
                                    refInFor: true,
                                    attrs: {
                                      id: sidebarItem.name + "_Menu",
                                      index: index,
                                      to:
                                        sidebarItem.slug != "external"
                                          ? sidebarItem.url
                                          : "",
                                      href:
                                        sidebarItem.slug == "external"
                                          ? sidebarItem.url
                                          : "",
                                      icon: sidebarItem.icon,
                                      target: sidebarItem.target,
                                      isDisabled: sidebarItem.isDisabled
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.sidebarItemsMin,
                                            expression: "!sidebarItemsMin"
                                          }
                                        ],
                                        staticClass: "truncate",
                                        attrs: { id: sidebarItem.name }
                                      },
                                      [_vm._v(_vm._s(sidebarItem.name))]
                                    ),
                                    sidebarItem.tag &&
                                    (_vm.isMouseEnter || !_vm.reduce)
                                      ? _c(
                                          "vs-chip",
                                          {
                                            staticClass: "ml-auto",
                                            attrs: {
                                              color: sidebarItem.tagColor
                                            }
                                          },
                                          [_vm._v(_vm._s(sidebarItem.tag))]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : [
                                  _c("vx-sidebar-group", {
                                    key: "group-" + index,
                                    ref: "sidebarGrp",
                                    refInFor: true,
                                    attrs: {
                                      openHover: _vm.openGroupHover,
                                      group: sidebarItem,
                                      groupIndex: index,
                                      open: _vm.isGroupActive(sidebarItem)
                                    }
                                  })
                                ]
                          ]
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }