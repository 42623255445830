export default [
  {
    url: "/google-accounts",
    name: "Cuentas",
    slug: "page2",
    icon: "icono-cuentas",
    user: "user",
  },
  {
    url: "/",
    name: "Estadísticas",
    slug: "statistics",
    icon: "icono-estadisticas",
    user: "user",
  },
  {
    url: "/traffic",
    name: "Tráfico",
    slug: "trafic",
    icon: "icono-trafico",
    user: "user",
  },
  {
    url: "/blacklist",
    name: "Lista Negra",
    slug: "blacklist",
    icon: "icono-prohibir",
    user: "user",
  },
  {
    url: "/whitelist",
    name: "Lista Blanca",
    slug: "whitelist",
    icon: "icono-estadisticas",
    user: "admin",
  },
  {
    url: "/user-rules",
    name: "Reglas de Negocio",
    slug: "page2",
    icon: "icono-opciones",
    user: "user",
  },
  {
    url: "/installation",
    name: "Instalación",
    slug: "installation",
    icon: "icono-install",
    user: "user",
  },
  {
    url: "/guest",
    name: "Referidos",
    slug: "guest",
    icon: "icono-referidos",
    user: "referer|admin",
  },
  {
    url: "/sales",
    name: "Ventas",
    slug: "sales",
    icon: "icono-ventas",
    user: "sales|admin",
  },
  {
    url: "/admin-page",
    name: "Admin",
    slug: "admin",
    icon: "icono-estadisticas",
    user: "admin",
  },
  {
    url: "/admin-trans",
    name: "Transacciones",
    slug: "admintrans",
    icon: "icono-estadisticas",
    user: "admin",
  },
  {
    url: "/clickstatus",
    name: "ClickStatus",
    slug: "clickstatus",
    icon: "icono-estadisticas",
    user: "admin",
  },
  /*{
    url: "/sorter",
    name: "Clasificar Clicks",
    slug: "page2",
    icon: "FileTextIcon",
  }*/
]
