var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "the-footer flex-wrap justify-between", class: _vm.classes },
    [
      _c("span", [
        _vm._v("\n    COPYRIGHT ©\n    "),
        _c(
          "a",
          { attrs: { href: "https://www.clickdefense.io", target: "_blank" } },
          [_vm._v(" ClickDefense")]
        ),
        _vm._v(
          " " + _vm._s(new Date().getFullYear()) + ". All rights reserved.\n  "
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }