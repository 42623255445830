var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vs-sidebar--item",
      class: [
        { "vs-sidebar-item-active": _vm.activeLink },
        { "disabled-item pointer-events-none": _vm.isDisabled }
      ]
    },
    [
      _vm.to
        ? _c(
            "router-link",
            {
              class: [{ "router-link-active": _vm.activeLink }],
              attrs: { to: _vm.to, target: _vm.target, exact: "" }
            },
            [
              !_vm.featherIcon
                ? _c("feather-icon", {
                    class: { "w-3 h-3": _vm.iconSmall },
                    attrs: { icon: _vm.icon }
                  })
                : _c("img", {
                    staticClass: "w-6 h-6 mr-3",
                    attrs: {
                      src: require("@/assets/images/icons/" + _vm.icon + ".png")
                    }
                  }),
              _vm._t("default")
            ],
            2
          )
        : _c(
            "a",
            { attrs: { target: _vm.target, href: _vm.href } },
            [
              !_vm.featherIcon
                ? _c("feather-icon", {
                    class: { "w-3 h-3": _vm.iconSmall },
                    attrs: { icon: _vm.icon }
                  })
                : _c("img", {
                    staticClass: "w-6 h-6 mr-3",
                    attrs: {
                      src: require("@/assets/images/icons/" + _vm.icon + ".png")
                    }
                  }),
              _vm._t("default")
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }